var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('FilterCustomer'),(!_vm.isLength)?_c('v-pagination',{attrs:{"length":_vm.lengthPage,"total-visible":7,"depressed":""},on:{"input":_vm.changePage},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e(),_c('v-data-table',{staticClass:"elavation-1",attrs:{"headers":_vm.headers,"items":_vm.customersList,"hide-default-footer":true,"item-key":"desserts.id","disable-sort":"","items-per-page":_vm.custPagination.per_page,"server-items-length":_vm.custPagination.total,"options":_vm.option,"footer-props":{
      'items-per-page-options': [
        _vm.custPagination.per_page != null ? _vm.custPagination.per_page : 10 ],
    },"page":_vm.custPagination.currentPage},on:{"update:options":function($event){_vm.option=$event}},scopedSlots:_vm._u([{key:"item.is_active",fn:function(ref){
    var item = ref.item;
return [_c('div',[_c('span',[_vm._v(" "+_vm._s(_vm.selectedStatus(item.is_active))+" ")])])]}},{key:"item.customer_name",fn:function(ref){
    var item = ref.item;
return [_c('a',{attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.toCustomerDetail(item.id)}}},[_vm._v(_vm._s(item.customer_name))])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.toDetail(item.id)}}},[_vm._v(" mdi-eye ")]),_c('v-icon',{attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-cancel ")])]}},{key:"top",fn:function(){return [_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Are you sure you want to delete this item?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v(" OK ")]),_c('v-spacer')],1)],1)],1)]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }