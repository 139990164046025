export const dataCustomer = 
[
  {
    id: 1,
    email: "lorem@mail.com",
    name: "loremIpsum",
    phone: "0812-3xxx-xxxx",
    birthday: "1993/2/28",
    sex: "female",
  },
  {
    id: 2,
    email: "lorem@mail.com",
    name: "loremIpsum",
    phone: "0812-3xxx-xxxx",
    birthday: "1993/2/28",
    sex: "female",
  },
  {
    id: 3,
    email: "lorem@mail.com",
    name: "loremIpsum",
    phone: "0812-3xxx-xxxx",
    birthday: "1993/2/28",
    sex: "female",
  },
  {
    id: 4,
    email: "lorem@mail.com",
    name: "loremIpsum",
    phone: "0812-3xxx-xxxx",
    birthday: "1993/2/28",
    sex: "female",
  },
  {
    id: 5,
    email: "lorem@mail.com",
    name: "loremIpsum",
    phone: "0812-3xxx-xxxx",
    birthday: "1993/2/28",
    sex: "female",
  },
  {
    id: 6,
    email: "lorem@mail.com",
    name: "loremIpsum",
    phone: "0812-3xxx-xxxx",
    birthday: "1993/2/28",
    sex: "female",
  },
  {
    id: 7,
    email: "lorem@mail.com",
    name: "loremIpsum",
    phone: "0812-3xxx-xxxx",
    birthday: "1993/2/28",
    sex: "female",
  },
  {
    id: 8,
    email: "lorem@mail.com",
    name: "loremIpsum",
    phone: "0812-3xxx-xxxx",
    birthday: "1993/2/28",
    sex: "female",
  },
  {
    id: 9,
    email: "lorem@mail.com",
    name: "loremIpsum",
    phone: "0812-3xxx-xxxx",
    birthday: "1993/2/28",
    sex: "female",
  },
  {
    id: 10,
    email: "lorem@mail.com",
    name: "loremIpsum",
    phone: "0812-3xxx-xxxx",
    birthday: "1993/2/28",
    sex: "female",
  },
];