<template>
  <v-container>
    <FilterCustomer />
    <!-- data table -->

    <v-pagination
      v-if="!isLength"
      v-model="page"
      :length="lengthPage"
      :total-visible="7"
      @input="changePage"
      depressed
    ></v-pagination>

    <v-data-table
      :headers="headers"
      :items="customersList"
      :hide-default-footer="true"
      item-key="desserts.id"
      disable-sort
      class="elavation-1"
      :items-per-page="custPagination.per_page"
      :server-items-length="custPagination.total"
      :options.sync="option"
      :footer-props="{
        'items-per-page-options': [
          custPagination.per_page != null ? custPagination.per_page : 10,
        ],
      }"
      :page="custPagination.currentPage"
    >
      <template v-slot:[`item.is_active`]="{ item }">
        <div>
          <span>
            {{ selectedStatus(item.is_active) }}
          </span>
        </div>
      </template>

      <!-- ADD CLICKABLE REDIRECT BY CUSTOMER NAME -->
      <template v-slot:[`item.customer_name`]="{ item }">
        <a href="" @click.prevent="toCustomerDetail(item.id)">{{
          item.customer_name
        }}</a>
      </template>

      <!-- action button itemlist loop -->
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon color="secondary" small class="mr-2" @click="toDetail(item.id)">
          mdi-eye
        </v-icon>
        <v-icon color="secondary" small @click="deleteItem(item)">
          mdi-cancel
        </v-icon>
      </template>
      <template v-slot:top="">
        <!-- dialog delete confirmation -->
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Are you sure you want to delete this item?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">
                Cancel
              </v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                OK
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- <v-data-footer
          :pagination="pagination"
          :options="options"
          @update:options="updateOptions"
          :items-per-page-text="$t('message.rows-per-page')"
          class="elavation-0 v-data-footer-top"
          :disable-items-per-page="true"
        /> -->
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { dataCustomer } from "../data/data-dummy-customer";
import FilterCustomer from "@/components/FilterCustomer";
export default {
  name: "CustomerManagement",
  components: {
    FilterCustomer,
  },
  data: () => ({
    dataCustomer: dataCustomer,
    dialog: false,
    dialogDelete: false,
    option: {},
    page: 1,
  }),

  created() {
    this.dispatchFetchCustomers();
  },

  watch: {
    customersList: {
      handler() {
        this.page = parseInt(this.$store.state.customer_module.page);
      },
      deep: true,
    },
  },

  computed: {
    customersList() {
      let customerList = this.$store.state.customer_module.customerListResult;

      if (customerList) {
        customerList.forEach((customer) => {
          if (customer.gender == "Male") {
            customer.gender = this.$t("message.label-male");
          } else {
            customer.gender = this.$t("message.label-female");
          }
        });
      }
      return customerList;
    },
    custPagination() {
      return this.$store.state.customer_module.customerPagination;
    },
    lengthPage() {
      return this.$store.state.customer_module.length_page;
    },
    isLength() {
      return this.$store.state.customer_module.is_length;
    },
    headers() {
      return [
        {
          text: this.$t("message.label-id"),
          align: "start",
          value: "id",
        },
        { text: this.$t("message.label-email"), value: "email" },
        { text: this.$t("message.label-name"), value: "customer_name" },
        { text: this.$t("message.label-furigana"), value: "furigana" },
        { text: this.$t("message.label-phone"), value: "phone" },
        { text: this.$t("message.label-birthday"), value: "bod" },
        { text: this.$t("message.label-sex"), value: "gender" },
        {
          text: this.$t("message.label-date-registration"),
          value: "created_at",
        },
        {
          text: this.$t("message.label-status"),
          value: "is_active",
          align: "center",
        },
        { text: this.$t("message.label-action"), value: "actions" },
      ];
    },
  },

  methods: {
    changePage(event) {
      return this.$store.dispatch("customer_module/fetchCustomerList", {
        page: event,
      });
    },
    dispatchFetchCustomers() {
      // const { page } = this.option
      let pageInit = parseInt(this.$store.state.customer_module.page);
      this.page = parseInt(this.$store.state.customer_module.page);
      this.$store.dispatch("customer_module/fetchCustomerList", {
        page: pageInit,
      });
    },
    toCustomerDetail(customer) {
      this.$router.push(`/customer/${customer}`);
    },
    toDetail(id) {
      // this.$store.dispatch("customer_module/fetchCustomerDetail", id);
      this.$router.push(`/customer/${id}`);
    },
    deleteItem(item) {
      // this.editedIndex = this.customerData.indexOf(item);
      // this.editedItem = Object.assign({}, item);
      // this.dialogDelete = true;
    },
    deleteItemConfirm() {
      // this.customerData.splice(this.editedIndex, 1);
      // this.closeDelete();
    },
    close() {
      this.dialog = false;
      // this.$nextTick(() => {
      //   this.editedItem = Object.assign({}, this.defaultItem);
      //   this.editedIndex = -1;
      // });
    },
    closeDelete() {
      this.dialogDelete = false;
      // this.$nextTick(() => {
      //   this.editedItem = Object.assign({}, this.defaultItem);
      //   this.editedIndex = -1;
      // });
    },
    selectedStatus(status) {
      if (status == 1) {
        return this.$t("message.label-active");
      } else {
        return this.$t("message.label-inactive");
      }
    },
  },
};
</script>

<style>
v-data-table >>> div > table > tr {
  border-spacing: 5px;
}
thead {
  background: #3949ab !important;
  text-transform: capitalize;
}

thead tr th {
  color: white !important;
  font-size: 15px !important;
}

tbody tr:hover:not(.v-table__expanded__content) {
  /* background: #c5cae9 !important; */
}
.v-data-footer-top {
  border-top: none !important;
}
</style>
