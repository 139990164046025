<template>
  <div>
    <h4 class="mb-5">{{ $t("message.title-customer") }}</h4>
    <v-form @submit.prevent="submitFilter">
      <v-row no-gutters align="center" justify="space-between">
        <v-col cols="10">
          <v-row >
            <v-col cols="12" md="3" xs="6">
              <span>{{ $t("message.label-email") }}</span>
              <v-text-field
                class="mt-1 "
                type="text"
                outlined
                clearable
                v-model="filter.email"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3" xs="6">
              <span>{{ $t("message.label-name") }}</span>
              <v-text-field
                class="mt-1 "
                type="text"
                outlined
                clearable
                v-model="filter.name"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3" xs="6">
              <span>{{ $t("message.label-phone") }}</span>
              <v-text-field
                class="mt-1 "
                type="text"
                outlined
                clearable
                v-model="filter.phone"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3" xs="6">
              <span>{{ $t("message.label-birthday") }}</span>
              <!-- <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="date"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filter.bod"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    prepend-inner-icon="mdi-calendar"
                    clearable
                    class=" mt-1"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="filter.bod" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal = false">
                    {{ $t("message.btn-cancel") }}
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.dialog.save(date)">
                    {{ $t("message.btn-ok") }}
                  </v-btn>
                </v-date-picker>
              </v-dialog> -->

              <v-menu
                v-model="modal"
                :close-on-content-click="false"
                :nudge-left="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
                >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                    class="mt-1"
                    outlined
                    clearable
                    v-model="filter.bod"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    style="font-size: 14px;"
                    ></v-text-field>
                </template>
                <v-date-picker
                    v-model="filter.bod"
                    @input="modal = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="3" xs="6">
              <span>{{ $t("message.label-sex") }}</span>
              <v-select
                class="mt-1 "
                outlined
                clearable
                v-model="filter.gender"
                :items="selectedGender"
                item-value="id"
                item-text="text"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col class="pr-2 pb-5 ps-8" cols="6" md="1" xs="6">
          <v-btn type="submit" rounded color="primary">
            <v-icon left>
              {{ iconSearch }}
            </v-icon>
            {{ $t("message.btn-search") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { mdiMagnify } from "@mdi/js";

export default {
  name: "FilterCustomer",
  data: () => ({
    iconSearch: mdiMagnify,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    modal: false,
    filter: {
      email: "",
      name: "",
      phone: "",
      bod: "",
      gender: "",
    },
  }),
  created() {
    this.initFilter();
  },
  methods: {
    submitFilter() {
      this.$store.dispatch("customer_module/fetchCustomerList", {
        email: this.filter.email == null || undefined ? "" : this.filter.email,
        name: this.filter.name == null || undefined ? "" : this.filter.name,
        phone: this.filter.phone == null || undefined ? "" : this.filter.phone,
        bod: this.filter.bod == null || undefined ? "" : this.filter.bod,
        gender: this.filter.gender == null || undefined ? "" : this.filter.gender,
        page: 1
      });
    },
    initFilter() {
      this.filter.email = this.$store.state.customer_module.email;
      this.filter.name = this.$store.state.customer_module.name;
      this.filter.phone = this.$store.state.customer_module.phone;
      this.filter.gender = this.$store.state.customer_module.gender;
      this.filter.bod = this.$store.state.customer_module.bod;
    },
  },

  computed: {
    selectedGender() {
      return [
        { id: "1", text: this.$t("message.label-male") },
        { id: "2", text: this.$t("message.label-female") }
      ]
    }
  },
};
</script>

<style>
.btn-search {
  border-radius: 20px;
}
.v-text-field .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}
.v-select.v-text-field--outlined:not(.v-text-field--single-line) .v-select__selections {
    padding: 0px !important;
}
.v-text-field--enclosed .v-input__prepend-inner {
  margin-top: 0px !important;
  align-self: center !important;
}
.v-text-field .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}
.v-text-field--enclosed .v-input__append-inner,
.v-text-field--enclosed .v-input__append-outer {
  margin-top: 0px !important;
  align-self: center !important;
}
@media only screen and (max-width: 640px) {
  .btn-search-filter-customer {
    float: none !important;
  }
  .col-mobile-width {
    width: 50% !important;
  }
}
</style>
